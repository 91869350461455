import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Case } from '../../sections/index'

import Layout from '../../layout/Layout'
import Seo from '../../components/Seo'
import hero from '../../images/banners/allency-banner-briefcase.png'
import SmallHeroBand from '../../sections/SmallHeroBand'
import { SmallBand } from '../../sections/BandSection'


const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {
      exImage: file(relativePath: { eq: "stock/allency-briefcase-run-santa-run.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      caseOne: file(relativePath: { eq: "stock/run-santa-run-1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseTwo: file(relativePath: { eq: "stock/run-santa-run-2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseThree: file(relativePath: { eq: "stock/run-santa-run-3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseFour: file(relativePath: { eq: "stock/run-santa-run-4.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseFive: file(relativePath: { eq: "stock/run-santa-run-5.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      caseSix: file(relativePath: { eq: "stock/run-santa-run-6.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

    }
  `)

  const caseOne = dataImage.caseOne.childImageSharp.gatsbyImageData
  const caseTwo = dataImage.caseTwo.childImageSharp.gatsbyImageData
  const caseThree = dataImage.caseThree.childImageSharp.gatsbyImageData
  const caseFour = dataImage.caseFour.childImageSharp.gatsbyImageData
  const caseFive = dataImage.caseFive.childImageSharp.gatsbyImageData
  const caseSix = dataImage.caseSix.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Portafolio',
    },
    case: {
      title: 'Run Santa Run',
      services: 'Publicidad | Desarrollo web | Mkt digital',
      description: `La carrera más divertida del año y con el mejor espíritu navideño. Nos sentimos muy orgullosos de ser sus aliados en publicidad, desarrollo de imagen y alcance en medios digitales para la ciudad de Monterrey, Guadalajara, y próximamente Mérida y Querétaro. `,
      images: [caseOne,caseTwo,caseThree,caseFour,caseFive,caseSix ]
    }
  }
  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Allency'}
        description={"Agencia de publicidad"}
        keywords={['Publicidad,Aliados,Estrategia,Marketing,Agencia,Digital,Resultados,Branding,Naming,Logotipo,Brochure,Creativo,Plan,Medios,Campañas,Programación,Código,Posicionamiento,Tráfico,Interfaces,Gestión,Multimedia,Diseño,Proyecto,Planes,Soluciones,Calidad,Startups,Pymes,Empresa,Eventos,Corporativos,Marcas,Portafolio']}
        noIndex={''}
        noFollow={''}
        slug={'run-santa-run'}
        canonicalUrl={'https://allency.com.mx/portfolio/run-santa-run'}
        ogTitle={'Allency'}
        ogType={'website'}
        ogDescription={"Agencia de publicidad"}
        ogImage={'../../images/logos/allency-dark.png'}
      />

      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Case contents={contents.case} />
      <SmallBand />
    </Layout>
  )
}

export default IndexPage